<template>
    <section class="anuncios-mi-anuncio ml-4">
        <div class="row mx-0 h-100">
            <div class="col-11 col-sm-11 col-md-12 col-lg-5 col-xl-5">
                <div class="row mt-4">
                    <div class="col-12">
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="f-300">
                            <el-breadcrumb-item :to="{ name: 'anuncios.main' }">Solicitudes de anuncios</el-breadcrumb-item>
                            <el-breadcrumb-item>Solicitud</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="col-12 mt-3">
                        <div v-if="(typeof cartelera.imagenes != 'undefined' && cartelera.imagenes.length > 0 )">
                          <vueper-slides class="vueperslides--1" ref="vueperslides1" :touchable="false" fade="fade" :autoplay="false" :bullets="false" @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })" fixed-height="325px" :arrows="false">
                              <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen"> </vueper-slide>
                          </vueper-slides>

                          <vueper-slides class="no-shadow vueperslides--2 mt-3" ref="vueperslides2" @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })" :visible-slides="cartelera.imagenes.length" fixed-height="87px" :bullets="false" :touchable="false" :gap="2.5" :arrows="false">
                              <vueper-slide v-for="(slide, i) in cartelera.imagenes" :key="i" :image="slide.imagen" @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)"></vueper-slide>
                          </vueper-slides>
                      </div>
                      <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="obj-cover h-100 w-100">
                    </div>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-md-6 col-lg-4 col-xl-4">
                <div class="row mx-0 mt-4">
                    <p class="f-20 col f-500">Información del anuncio</p>
                    <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                      <div slot="content" class="tooltip-editar-residente">
                          <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="editarAnuncio">
                              <p class="f-15">Editar</p>
                          </div>
                          <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="finalizarAnuncio">
                              <p class="f-15">Finalizar</p>
                          </div>
                          <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="republicarAnuncio">
                              <p class="f-15">Republicar</p>
                          </div>
                          <div class="d-flex editar-opcion py-1 px-2 cr-pointer br-4" @click="eliminarAnuncio">
                              <p class="f-15">Eliminar</p>
                          </div>
                      </div>
                      <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer">
                          <i class="icon-vertical-points" />
                      </div>
                    </el-tooltip>
                    <p class="f-15 col-12 mt-3">{{cartelera.titulo}}</p>
                    <p class="f-17 col-12">{{convertMoney(cartelera.dinero)}}</p>
                    <div class="col-auto my-2">
                        <div class="categoria d-middle br-20 px-2 f-12" :style="`background: ${_.get(cartelera.categoria, 'color')}`">
                            <i class="icon-shop-outline mr-2 op-05" />
                            <p class="tres-puntos op-05">{{_.get(cartelera.categoria, 'nombre')}}</p>
                        </div>
                    </div>
                    <div class="col-auto my-2 ml-auto">
                        <div v-if="cartelera.estado==1" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Pendiente</p>
                          </div>
                          <div v-if="cartelera.estado==2" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Aprobado</p>
                          </div>
                          <div v-if="cartelera.estado==3" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Rechazado</p>
                          </div>
                          <div v-if="cartelera.estado==4" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Cancelado Admin</p>
                          </div>
                          <div v-if="cartelera.estado==5" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Cancelado Cliente</p>
                          </div>
                          <div v-if="cartelera.estado==6" class="categoria d-middle br-20 px-2 f-12 bg-activo">
                              <p class="tres-puntos op-05">Vencido</p>
                          </div>
                    </div>
                    <div class="col-auto my-2">
                        <div class="categoria d-middle br-20 px-2 f-12 bg-db">
                            <p class="tres-puntos op-05">{{ cartelera.vigencia_inicio | helper-fecha('DD MMM') }} - {{cartelera.vigencia_fin | helper-fecha('DD MMM')  }}</p>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-2">
                     <div class="col-12">
                        <div class="d-flex">
                            <div class="wh-32px rounded-circle d-middle-center my-auto" style="background:#80DCFF">
                                <i class="icon-account-outline op-05 f-20" />
                            </div>
                            <div class="tres-puntos ml-2">
                                <p class="f-15 f-500">{{_.get(cartelera.usuario, 'nombre')}}</p>
                                <p class="f-12 tres-puntos">{{_.get(cartelera.usuario, 'vivienda')}}</p>
                            </div>
                        </div>
                     </div>
                </div>
                <div class="row mx-0 mt-3 mb-2">
                    <div class="col-12">
                        <p class="f-15">{{_.get(cartelera, 'descripcion')}}</p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12 col-lg-auto d-flex">
                        <i :class="`icon-pin mr-2 cr-pointer f-17 ${cartelera.top == 1 ? 'text-general' : ''}`" @click="cartelera.top = (cartelera.top == 1) ? 0 : 1" />
                        <p class="f-14">Fijar el anuncio</p>
                    </div>
                    <div class="col-12 col-lg-auto d-flex el-checkbox-negro">
                        <el-checkbox v-model="cartelera.destacado">Destacar el anuncio</el-checkbox>
                    </div>
                </div>
            </div>
            <div class="col-11 col-sm-11 col-md-6 col-lg-3 col-xl-3 pr-0 box-shadow-left">
                <div class="row mx-0 my-4">
                    <p class="col-12 f-500 f-20">Personas interesadas</p>
                </div>
                <div class="container-right-side scroll-none overflow-auto">
                    <div v-for="(interesado,i) in interesados" :key="i" class="row mx-0 mb-3">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="wh-32px rounded-circle d-middle-center" style="background:#80DCFF">
                                    <i class="icon-account-outline op-05 f-20" />
                                </div>
                                <div class="tres-puntos ml-2">
                                    <p class="f-15 f-500">{{interesado.nombre}}</p>
                                    <p class="f-13 tres-puntos">{{interesado.telefono}}</p>
                                    <p class="f-12 tres-puntos">{{interesado.vivienda}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-confirmar ref="modalabrirFinalizarAnuncio" titulo="Finalizar anuncio" mensaje="¿Desea finalizar el anuncio?" tamano="modal-sm" adicional="Finalizar" />
        <modal-rechazar-anuncio ref="modalabrirRechazarAnuncio" />
        <modal-eliminar ref="modalabrirEliminarAnuncio" titulo="Eliminar anuncio" mensaje="¿Desea eliminar el anuncio?" tamano="modal-md" class="f-15 text-center" />
    </section>
</template>
<script>
import Carteleras from '~/services/carteleras'
export default {
    components:{
        modalRechazarAnuncio: () => import('./partials/modalRechazarAnuncio'),
    },
    data(){
        return{
            
            fijarAnuncioCheck: false,

            duracion_dias : 1,
            cartelera:{},
            interesados:[],
        }
    },
    created(){
      this.mostrar()
      this.interesadosF()
    },
    methods:{
        fijarAnuncio(){
            this.fijarAnuncioCheck = !this.fijarAnuncioCheck
        },
        eliminarAnuncio(){
            this.$refs.modalabrirEliminarAnuncio.toggle()
        },
        editarAnuncio(){
            this.$router.push({ name: 'anuncios.crear' })                

        },
        finalizarAnuncio(){
            this.$refs.modalabrirFinalizarAnuncio.toggle()
        },
        republicarAnuncio(){
            this.$router.push({ name: 'anuncios.crear' })            

        },
        async mostrar(){
          try {
            this.loading = true
            
            const {data} = await Carteleras.mostrar(this.$route.params.id_cartelera)
            this.cartelera = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
        async interesadosF(){
          try {
            this.loading = true            
            const {data} = await Carteleras.interesados(this.$route.params.id_cartelera)
            this.interesados = data.data
            this.loading = false
          } catch (e){
              console.log(e)
              this.loading = false
          }  
        },
    }
}
</script>
<style lang="scss" scoped>
.anuncios-mi-anuncio{
    height: calc(100vh - 55px);
    
    .vueperslides--2{
        .vueperslide{
            cursor: pointer;
        }
        .vueperslide--active{
            border: 2px solid #109881;
            border-radius: 4px;
        }
    }
    .vueperslide, .vueperslides__parallax-wrapper{
        border-radius: 4px !important;
        
    }
    .categoria{
        min-height: 22px;
    }
    .box-shadow-left{
        box-shadow: -4px 0px 6px #00000014;
    }
    .container-right-side{
        // background: #fff 0% 0% no-repeat padding-box; box-shadow: 0px 3px 6px #00000014; 
        // border: 1px solid #dbdbdb; 
        height: calc(100vh - 140px); 
        position: relative;
        .icon-pin::before{
            margin-left: 0px;
        }
        .btn-{
            &aprobar{
                background: #93EDCF;
            }
            &rechazar{
                background: #FFD54F;
            }
            &eliminar{
                background: #FFB2C2;
            }
        }
    }
}
</style>